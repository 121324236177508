import React from "react"
import { useStaticQuery,graphql } from "gatsby"
var striptags = require('striptags');

const Excerpt = (props) => {
  var output ="";
  const allPages = [];

  const data =useStaticQuery(graphql`
  query GetPost{
    wpgraphql {
      coastguards(first:100)
      {
        nodes{
          id
          title
          excerpt
          link
        }
      }
      }
    }
  `);
    
  if(!data){ return}
    else{ 
     
      if (typeof props.pagelink !== 'undefined') {
        data.wpgraphql.coastguards.nodes.forEach(node => {
          if (node.link === "https://pojcontentlinux.azurewebsites.net"+props.pagelink.toLowerCase()+"/" || node.link === "https://pojcontentlinux.azurewebsites.net"+props.pagelink.toLowerCase()){
          allPages.push({"text":node.excerpt});
          output=allPages[0].text;
          return;  
        }
      })
    };
     
  }
return <span dangerouslySetInnerHTML={{ __html: striptags(output, '<p>') }}></span>;
}
 
export default Excerpt